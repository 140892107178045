import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import GuideView from '../views/GuideView.vue';
import HomeView from "../views/HomeView.vue";
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Guide',
        component: GuideView,
        meta: {
            spaceKeys: ['Guide_native', 'Guide_banner'],
        },
    },
    {
        path: "/index",
        name: "Index",
        component: HomeView,
        redirect: '/topic',
        children: [
            {
                path: '/main',
                name: 'Home',
                component: () => import('../views/home/Main.vue'),
                meta: {
                    spaceKeys: ['Home_inter', 'Home_dialog', 'Home_banner', 'Main_native'],
                },
            },
            {
                path: '/classroom',
                name: 'Classroom',
                component: () => import('../views/home/Classroom.vue'),
                meta: {
                    spaceKeys: ['Home_inter', 'Home_dialog', 'Home_banner', 'Classroom_native'],
                },
            },
            {
                path: '/wikipedia',
                name: 'Wikipedia',
                component: () => import('../views/home/Wikipedia.vue'),
                meta: {
                    spaceKeys: ['Home_inter', 'Home_dialog', 'Home_banner', 'Wikipedia_cat_native', 'Wikipedia_dog_native', 'Wikipedia_other_native'],
                },
            },
            {
                path: '/constellation',
                name: 'Constellation',
                component: () => import('../views/home/Constellation.vue'),
                meta: {
                    spaceKeys: ['Home_inter', 'Home_dialog', 'Home_banner'],
                },
            }
        ],
    },
    {
        path: '/article-detail',
        name: 'ArticleDetail',
        component: () => import('../views/ArticleDetail.vue'),
        meta: {
            spaceKeys: ['ArticleDetail_inter', 'ArticleDetail_banner', 'ArticleDetail_native'],
        },
    },
    {
        path: '/pet-detail',
        name: 'PetDetail',
        component: () => import('../views/PetDetail.vue'),
        meta: {
            spaceKeys: ['PetDetail_inter', 'PetDetail_banner', 'PetDetail_native'],
        },
    },
    {
        path: '/constellation-list',
        name: 'ConstellationList',
        component: () => import('../views/ConstellationList.vue'),
        meta: {
            spaceKeys: ['ConstellationList_banner', 'ConstellationList_native'],
        },
    },
    {
        path: '/mood',
        name: 'Mood',
        component: () => import('../views/MoodView.vue'),
        meta: {
            spaceKeys: ['Mood_dialog'],
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const { useParams } = require('@/store/useParams');
    const { params } = useParams();
    if (to.path == '/' && params.skip == 1) {
        oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'skip1EnterHome');
        next('/main');
    } else {
        next();
    }
})

export default router;
